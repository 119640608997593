import { APIFilter } from '@/utils/api'
import _ from '@/utils/lodash'

export default {
  async selectTecnicoAnnadirParte(Vue, idparteTrabajo) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idparte_trabajo', idparteTrabajo)
    const datasetTecnicosEnElParte = await Vue.$api.call('parteTrabajoTecnico.select', { filter: apiFilter })
    const idtecnicosEnElParte = _.map(datasetTecnicosEnElParte.data.result.dataset, 'idtecnico')
    const apiFilterExclude = new APIFilter()
    apiFilterExclude
      .addIn('idtecnico', idtecnicosEnElParte, true)
      .addGT('estado', 0)
    const datasetTecnicosDisponibles = await Vue.$api.call('tecnico.select', { filter: apiFilterExclude })
    return [datasetTecnicosDisponibles.data.result.dataset, datasetTecnicosDisponibles.data.result.metadata]
  },
}
