<template>
  <b10-base>
    <b10-autocomplete
      v-model="form.idtecnico"
      :items="tecnicos"
      item-value="idtecnico"
      item-text="nombre_y_apellido"
      label="Técnico"
      clearable
      :rules="formRules.idtecnico"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './ParteTrabajoTecnicoFormData'

export default {
  mixins: [formMixin],
  props: {
    idparteTrabajo: {
      type: [Number, String],
      required: true
    },
  },
  data () {
    return {
      form: {
        idtecnico: null,
      },
      formRules: {
        idtecnico: [
          v => !!v || 'Campo requerido'
        ],
      },
      tecnicos: [],
    }
  },
  watch: {
    form: {
      handler: function (newValue) {
        this.$emit('change-values', newValue)
      },
      deep: true,
    },
  },
  async created () {
    const [datasetTecnicosDisponibles] = await Data.selectTecnicoAnnadirParte(this, this.idparteTrabajo)
    this.tecnicos = datasetTecnicosDisponibles
  },
  methods: {
  }
}
</script>
